<template>
  <v-container>
    <div align='center'>
      <v-card max-width='500' class='mt-5' rounded='xl' elevation='4'>
        <v-card-title>
          <v-spacer></v-spacer>
          <span class='set-font'>{{ $t('confirmPayWithPaySulotion') }}</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols='6' class='text-right set-font-title py-1'> ข้อมูลคลินิก :</v-col>
            <v-col cols='6' class='text-left set-font-normal py-1'
            >({{ payData.shop_id }}) {{ payData.shop_name }}
            </v-col
            >
            <v-col cols='6' class='text-right set-font-title py-1'> Package :</v-col>
            <v-col cols='6' class='text-left set-font-normal py-1'>{{ payData.packageshop_name }}
            </v-col>
            <v-col cols='6' class='text-right set-font-title py-1'> ราคารวม :</v-col>
            <v-col cols='6' class='text-left set-font-normal py-1'>
              {{ payData.order_totalpay | formatPrice }}
              บาท
            </v-col>
          </v-row>
        </v-card-text>
        <form method='post' action='https://www.thaiepay.com/epaylink/payment.aspx'>
          <div>
            <input type='hidden' name='customeremail' value='apsth456@gmail.com' />
            <input type='hidden' name='productdetail' v-model='payData.packageshop_name' />
            <input type='hidden' name='refno' v-model='payData.refno' />
            <input type='hidden' name='merchantid' v-model='payData.paysolutions_merchant' />
            <input type='hidden' name='cc' value='00' />
            <input type='hidden' name='total' v-model='payData.order_totalpay' />
            <input type='hidden' name='lang' lang='th' />
            <input type='hidden' name='postbackurl' v-model='payData.postback' />
            <input type='hidden' name='returnurl' v-model='returnurl' />
          </div>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='#002cc9' class='set-font white--text' type='submit'>ยืนยัน</v-btn>
            <!-- <v-btn
            outlined
            color="error"
            class="set-font"
            :href="`https://app-apsth.com/orderdetial/${payData.order_id_pri}`"
            >ยกเลิก</v-btn
          > -->
            <v-spacer></v-spacer>
          </v-card-actions>
        </form>
      </v-card>
    </div>
  </v-container>
</template>


<script>
import { formatPrice } from '@/plugins/filters'

export default {
  name: 'IndexPage',
  filters: {
    formatPrice,
  },
  data() {
    return {
      orderId: '',
      returnurl: 'https://app-apsth.com/paysolutionssuccess',
      payData: {
        refno: '',
        paysolutions_merchant: '',
        shop_id: '',
        shop_id_pri: '',
        shop_name: '',
        postback: '',
        packageshop_name: '',
        order_totalpay: '',
        packageshop_id: '',
      },
    }
  },
  created() {
    this.payData.refno = this.$route.query.refno
    this.payData.paysolutions_merchant = this.$route.query.paysolutions_merchant
    this.payData.shop_id = this.$route.query.shop_id
    this.payData.shop_id_pri = this.$route.query.shop_id_pri
    this.payData.shop_name = this.$route.query.shop_name
    this.payData.postback = this.$route.query.postback
    this.payData.packageshop_name = this.$route.query.packageshop_name
    this.payData.order_totalpay = this.$route.query.order_totalpay
    this.payData.packageshop_id = this.$route.query.packageshop_id
  },
  mounted() {
    let paramsQurey = ''
    let data = {
      refno: this.payData.refno,
      paysolutions_merchant: this.payData.paysolutions_merchant,
      shop_id_pri: this.payData.shop_id_pri,
      shop_id: this.payData.shop_id,
      shop_name: this.payData.shop_name,
      packageshop_name: this.payData.packageshop_name,
      order_totalpay: this.payData.order_totalpay,
      packageshop_id: this.payData.packageshop_id,
    }
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        paramsQurey += `${key}=${value}&`
      }
    }

    this.payData.postback += `?${paramsQurey}`
    console.log('postback', this.payData.postback)
  },
}
</script>
s
